body,html{
  padding: 0;
  margin: 0;
  top:0;
  left: 0;
}
ul,li{
  list-style: none;
}
input{
  color: #999999;
  /*border: none;*/
  outline: none;
  text-decoration: none;
  width: 100%;
  height: calc(88 / 750 * 100vw);
  border-radius: calc(16 / 750 * 100vw);
  border: calc(2 / 750 * 100vw) solid #E5E5E5;
  margin-top: calc(24 / 750 * 100vw);
  box-sizing: border-box;
  font-size: calc(24 / 750 * 100vw);
  padding: 0 calc(24 / 750 * 100vw);
}

.serve{
  width: calc(200 / 750 * 100vw);
  height: calc(166 / 750 * 100vw);
  position: fixed;
  z-index: 12;
}