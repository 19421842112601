$vw_base: 750;
$vh_base: 1440;

@function vw($px) {
  @return calc($px / $vw_base) * 100vw;
}

@function vh($px) {
  @return calc($px / $vh_base) * 100vh;
}

.cm-header {
  background-image: linear-gradient(-133deg, #fa6c42 0%, #ff0a19 100%);
  color: #ffffff;
  position: fixed;
  top: -1px;
  left: 0;
  z-index: 30;
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}
